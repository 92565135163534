import { IconButton as MuiIconButton, styled } from '@mui/material';

import IconButtonProps from '../types';

const SocialStyled = styled(MuiIconButton)<IconButtonProps>(({ theme }) => ({
    borderRadius: 6,
    color: theme.palette.cta.main,
    backgroundColor: theme.palette.common.white,
    padding: '0',
    height: theme.spacing(10),
    width: theme.spacing(10),

    '&.MuiButton-sizeSmall': {
        height: theme.spacing(8),
        width: theme.spacing(8),
    },

    '&.MuiButton-sizeLarge': {
        height: theme.spacing(12),
        width: theme.spacing(12),
    },
    '&:hover, &.Mui-hoverVisible': {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.cta.main,
    },
    '&.Mui-focusVisible': {
        boxShadow: theme.shadows[24],
    },
    '&.Mui-disabled': {
        opacity: '0.6',
    },
}));

export default SocialStyled;
