import { IconButton as MuiIconButton, styled, alpha } from '@mui/material';

import IconButtonProps from '../types';

const SecondaryStyled = styled(MuiIconButton)<IconButtonProps>(({ theme }) => ({
    borderRadius: theme.radius.all,
    color: theme.palette.cta.main,
    backgroundColor: alpha(theme.palette.cta.main, 0.07),
    padding: '0',
    height: theme.spacing(10),
    width: theme.spacing(10),

    '&.MuiIconButton-sizeSmall': {
        height: theme.spacing(8),
        width: theme.spacing(8),
    },

    '&.MuiIconButton-sizeLarge': {
        height: theme.spacing(12),
        width: theme.spacing(12),
    },
    '&:hover, &.Mui-hoverVisible': {
        backgroundColor:
            theme.palette.mode === 'light' ? alpha(theme.palette.cta.main, 0.15) : theme.palette.background.lighter,
    },
    '&.Mui-focusVisible': {
        boxShadow: theme.shadows[24],
    },
    '&.Mui-disabled': {
        opacity: '0.6',
    },
}));

export default SecondaryStyled;
