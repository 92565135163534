import { IconButton as MuiIconButton, styled, alpha } from '@mui/material';

import IconButtonProps from '../types';

const TertiaryStyled = styled(MuiIconButton, {
    shouldForwardProp: (prop: string) => !['overrideColor'].includes(prop),
})<IconButtonProps>(({ theme, overrideColor }) => ({
    borderRadius: theme.radius.all,
    color: overrideColor || theme.palette.cta.main,
    backgroundColor: 'transparent',
    height: theme.spacing(10),
    width: theme.spacing(10),

    '&.MuiIconButton-sizeSmall': {
        height: theme.spacing(8),
        width: theme.spacing(8),
    },
    '&.MuiIconButton-sizeLarge': {
        height: theme.spacing(13),
        width: theme.spacing(13),
    },
    '&:hover, &.Mui-hoverVisible': {
        backgroundColor: overrideColor ? alpha(overrideColor, 0.07) : alpha(theme.palette.cta.main, 0.05),
    },
    '&.Mui-focusVisible': {
        boxShadow: theme.shadows[24],
    },
    '&.Mui-disabled': {
        opacity: '0.6',
    },
}));

export default TertiaryStyled;
