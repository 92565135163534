import React from 'react';

import { IconButton as MuiIconButton } from '@mui/material';

import MenuStyled from './components/Menu';
import SecondaryStyled from './components/Secondary';
import SocialStyled from './components/Social';
import TertiaryStyled from './components/Tertiary';
import IconButtonProps from './types';

declare module '@mui/material/IconButton' {
    interface IconButtonPropsVariantOverrides {
        social: true;
        menu: true;
        secondary: true;
    }
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(({ variant, ...props }, ref) => {
    if (variant === 'secondary') {
        return <SecondaryStyled ref={ref} {...props} />;
    }

    if (variant === 'tertiary') {
        return <TertiaryStyled ref={ref} {...props} />;
    }

    if (variant === 'social') {
        return <SocialStyled ref={ref} {...props} />;
    }

    if (variant === 'menu') {
        return <MenuStyled ref={ref} {...props} />;
    }

    return <MuiIconButton ref={ref} {...props} />;
});

IconButton.displayName = 'IconButton';

export default IconButton;
